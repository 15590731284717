<!--已邀请 的用户列表-->
<template>
    <div class="page">
      <div class="loding" v-if="showLoding">
        <van-loading type="spinner" color="#1989fa" >加载中...</van-loading>
      </div>

      <div v-if="!showLoding">
        <div class="my-top">
          <div class="info-box" style="">
            <div class="head-portrait">
              <div class="box">
                <div class="treasure-font">
                  -已邀请-
                </div>
                <div class="treasure-num">{{ list.length }}</div>
              </div>
              <van-button plain hairline round type="info" size="small" class="treasure-btn" @click="mask=true">去邀请</van-button>
            </div>
          </div>
        </div>
        <div class="list-box">
          <div class="my-list-cell">
            <div class="historical-records">
              已邀请的用户
            </div>
            <div class="resume-cell" v-for="i in list">
              <div>
                <van-icon name="peer-pay"/>
                <span>{{ i.userName }}</span>
              </div>
              <div class="eidt-font">
                       <span>
                           {{ i.createdDate }}
                       </span>
                <span class="span-right">
                    <van-button plain hairline round type="info" size="small" class="span-btn">{{ i.status=='1'?"已认证":"未认证" }}</van-button>
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mask" @touchmove.prevent @click="mask=false"  v-if="mask == true">
          <div class="maskImg">
            <img src="@/assets/images/jt.png" alt />

          </div>
          <div class="share-txt">
            <div class="title">立即分享给好友吧</div>
            <div style="font-size: 18px;">点击屏幕右上角分享给好友</div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import {getInvite} from "../../api/user";
import {getTicket} from "../../api/auth";
import store from "../../store"
import wx from 'weixin-js-sdk'
    export default {
        data() {
            return {
              showLoding: true,
              mask: false,
              list:[],
              userInfo:{},
              params: {
                page: 0,
                size: 40,
              },
            }
        },
        mounted() {
          this.userInfo = JSON.parse(`${store.state.user}`)
          console.info("myInvite mounted userInfo",this.userInfo)
          this.loadData()
          this.loadTicket()
        },
        methods: {
            goMyTreasure() {
                this.$router.push(`my-treasure`)
            },
          async loadTicket(){
              let _this = this;
            let r = await getTicket({page:"my-invite"});
            let ticket = r.data;
            wx.config({
              // debug: true, // 开启调试模式
              appId: ticket.appId, // 必填，公众号的唯一标识
              timestamp: ticket.timestamp, // 必填，生成签名的时间戳
              nonceStr: ticket.nonceStr, // 必填，生成签名的随机串
              signature: ticket.signature,// 必填，签名
              jsApiList: [
                "updateAppMessageShareData",//自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                "updateTimelineShareData",//自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                'onMenuShareAppMessage',  //旧的接口，即将废弃
              ] // 必填，需要使用的JS接口列表
            })
            //通过ready接口处理成功验证
            wx.ready(function(){
              _this.showLoding=false;
              _this.wxShareTimeline();
              // this.wxShareAppMessage();

// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            });
          },
          async loadData() {
            let r = await getInvite();
            this.list = r.data
          },
          wxShareTimeline() {// 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
            wx.onMenuShareAppMessage({
              title: '【活可多】起重人才聚集地', // 分享标题
              desc: '起重招人就上“活可多”，靠谱工匠随心选', // 分享描述
              link: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1a86754b82cee013&redirect_uri=http://mp.91workman.com/auth?page=select%26back=home%26pid="+this.userInfo.id+"%26userType=CUSTOM%26type=shareInvite&pid=&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: 'http://admin.91workman.com/app/img/detail/logo.png', // 分享图标
              success: () => {

              }
            })
          },
        },
        computed: {}
    }

</script>

<style lang="less" scoped>

.loding{
  text-align:center;
  padding: 20px;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.2);
}
.maskImg {
  position: fixed;
  top: 6px;
  right: 26px;
  z-index: 100000;
  //width: 507upx;
}
.maskImg img {
  width: 80px;
}
.share-txt{
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-right: 25px;
  .title{
    font-size: 22px;
  }
}

    .my-top {
        height: 280px;
        width: 100%;
        background: linear-gradient(to right, #0E91FC, #08ADFD);

        .info-box {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: space-around;

            .head-portrait {
                margin-top: 15%;
                width: 120px;
                height: 120px;
                box-sizing: border-box;
                padding: 5px;
                border-radius: 50%;
                background-image: linear-gradient(#FFFFFF, #08ADFD);

                .box {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: #FFFFFF;
                    text-align: center;

                    .treasure-font {
                        padding-top: 10px;
                    }

                    .treasure-num {
                        margin-top: 10px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }

                .treasure-btn {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -20px);
                    background: linear-gradient(to right, #F7D48D, #FDA65D);
                    color: #A04306;
                    border: none;
                    font-size: small;
                }
            }
        }
    }

    .list-box {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        height: calc(100vh - 200px);
        margin-top: -30px;
        background: #FFFFFF;

        .historical-records {
            margin-top: 20px;
            margin-left: 15px;
            font-size: 20px;
            color: #3C3C3C;
            font-weight: 600;
        }

        .my-list-cell {
            margin-top: 20px;
            position: absolute;
            width: 100%;

            .resume-cell {
                max-width: 700px;
                padding: 15px;
                margin-top: 10px;
                margin-left: 5px;
                border-bottom: 1px solid #F7F7F7;
                color: #212121;
                font-size: 16px;

                span {
                    margin-left: 10px;
                }

                .eidt-font {
                    color: #C3C3C3;
                    font-size: xx-small;
                    margin-left: 15px;
                    margin-top: 10px;

                    .span-right {
                        float: right;
                        margin-right: 10px;
                        .span-btn {
                            height: 20px;
                            width: 62px;
                            background: linear-gradient(to right, #F7D48D, #FDA65D);
                            color: #FFFFFF;
                            border: none;
                        }
                    }
                }
            }
        }
    }

</style>
